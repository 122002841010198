import React from "react";
import chart from "../assets/sentimentChart.png";
import gif from "../assets/sentaigif.gif";

const FinalSection = () => {
  return (
    <section className="bg-transparent mt-20 rounded-2xl py-12 px-4 sm:px-6 lg:px-64">
      <div className="max-w-screen-lg mx-auto">
        {/* Chart and CTA elements go here */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          {/* Chart placeholder (you will replace this with your actual chart component or image) */}
          <img src={gif} className="rounded-2xl" />
          {/* CTA Section */}
          <div className="text-center md:text-left">
            <h2 className="text-3xl font-bold text-white mb-4">
              $SENT powers our entire analysis suite
            </h2>
            <p className="text-gray-400 mb-8">
              Get Started by buying $SENT on Uniswap. Exchanges coming soon :)
            </p>
            <a
              href="https://www.dextools.io/app/en/ether/pair-explorer/0xb42cf248b2e1a309183762ad4a050836e6303fac"
              className="text-sm hover:bg-white bg-transparent border border-0.5 border-gray-600 text-white hover:text-black transition-all duration-300 ease-in-out font-bold py-2 px-4 rounded-xl"
            >
              Buy $SENT
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FinalSection;
