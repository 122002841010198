import React from "react";

const Button = ({ onClick = () => {}, message, disabled, type = "" }) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      type={type}
      className={`border rounded-xl px-4 py-2 relative overflow-x-hidden flex items-center justify-center transition-all ease-in-out duration-300 
        ${
          disabled
            ? "border-gray-600 cursor-not-allowed"
            : "border-gray-600 cursor-pointer hover:border-gray-600-500 hover:scale-110 group"
        }`}
    >
      <div
        className={`absolute h-1/5 w-1/5 bg-white top-0 -right-5  opacity-[0%] rounded-lg ease-in-out duration-500 transition  transition-all  
        ${
          disabled ? "group-hover:opacity-[0%]" : "group-hover:opacity-[100%]"
        } group-hover:right-0 group-hover:w-full group-hover:h-full`}
      />
      <div
        className={`absolute h-1/5 w-1/5 bg-white bottom-0 -left-5  opacity-[0%] rounded-lg ease-in-out duration-500 transition  transition-all  
        ${
          disabled ? "group-hover:opacity-[0%]" : "group-hover:opacity-[50%]"
        } group-hover:left-0 group-hover:w-full group-hover:h-full`}
      />
      <div
        className={`z-[100] flex flex-col justify-center 
        ${
          disabled ? "text-gray-300" : "group-hover:text-black"
        } transition-all ease-in-out duration-500 group-hover:scale-110`}
      >
        {message}
      </div>
    </button>
  );
};

export default Button;
