import React from "react";

const Footer = () => {
  return (
    <div className="p-4 text-gray-500 mt-20">
      Sentimentaixyz@proton.me
      <br />
      Sentiment AI
    </div>
  );
};

export default Footer;
