import React from "react";

const cardData = [
  {
    title: "💎 Analyze Telegram Sentiment",
    description:
      "By linking a Telegram channel, our bots perform sentiment analysis of the conversation to pick up on hidden insights, info or alpha.",
    buttonText: "Read More",
  },
  {
    title: "📈 In Depth Chart Analysis",
    description:
      "Our bots cross reference social sentiment with chart activity to give the most comprehensive overview on what’s moving token prices.",
    buttonText: "Read More",
  },
  {
    title: "💰 Curated list of the hypest tokens",
    description:
      "We analyze hundreds of conversations across Twitter, utilizing vector search technology to glean public opinion and real time sentiment.",
    buttonText: "Read More",
  },
];

const Card = ({ title, description, buttonText }) => {
  return (
    // The outer div should be a flex container with flex column direction.
    <div className="flex flex-col bg-[#0A0A0A] rounded-xl p-6 shadow-lg border border-gray-600 h-full">
      <div className="flex-grow">
        <h3 className="text-xl font-bold mb-2">{title}</h3>
        <p className="text-gray-400 mb-4 flex-grow">{description}</p>
      </div>
      {/* This button will be pushed to the bottom */}
      <button
        onClick={() => {
          window.open("https://twitter.com/sentimentai_xyz", "_blank");
        }}
        className="mt-auto text-sm hover:bg-white bg-transparent border border-gray-600 text-white hover:text-black transition-all duration-300 ease-in-out font-bold py-2 px-4 rounded-xl"
      >
        {buttonText}
      </button>
    </div>
  );
};

const CardsSection = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 lg:px-64">
      {cardData.map((card, index) => (
        <Card
          key={index}
          title={card.title}
          description={card.description}
          buttonText={card.buttonText}
        />
      ))}
    </div>
  );
};

export default CardsSection;
