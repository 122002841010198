import React, { useEffect, useState } from "react";
import logo from "../assets/sentiment2T.png";

const Navbar = () => {
  const [top, setTop] = useState(true);

  const scrollHandler = () => {
    window.pageYOffset > 10 ? setTop(false) : setTop(true);
  };

  useEffect(() => {
    scrollHandler();
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);

  return (
    <nav
      className={`fixed w-full bg-transparent text-white py-4 z-[10000] px-8 flex justify-between items-center z-30 md:bg-opacity-90 transition duration-300 ease-in-out ${
        !top ? "bg-transparent backdrop-blur-sm shadow-lg " : ""
      }`}
    >
      <div className="flex items-center">
        <img src={logo} alt="Logo" className="h-8 mr-12" />{" "}
      </div>
      <div>
        <a
          href="https://twitter.com/sentimentai_xyz"
          className="text-white px-4 py-2 hover:text-gray-300 "
        >
          Twitter
        </a>
        <a
          href="https://t.me/sentimentaiportal"
          className="text-white px-4 py-2 hover:text-gray-300"
        >
          Telegram
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
