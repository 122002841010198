import React, { useEffect } from "react";
import Button from "./Button";
import background from "../assets/bg.webp";
import "./HeroSection.css";

const HeroSection = () => {
  const animate = (star) => {
    const rand = (min, max) =>
      Math.floor(Math.random() * (max - min + 1)) + min;

    star.style.setProperty("--star-left", `${rand(-10, 100)}%`); 
    star.style.setProperty("--star-top", `${rand(-40, 80)}%`);

    star.style.animation = "none";
    void star.offsetWidth;
    star.style.animation = "";
  };

  useEffect(() => {
    const stars = document.getElementsByClassName("magic-star");
    let index = 0;
    const interval = 1000;

    for (const star of stars) {
      setTimeout(() => {
        animate(star);

        setInterval(() => animate(star), interval);
      }, index++ * (interval / 3));
    }
  }, []);
  return (
    <section className="text-center py-20 mt-10 md:mt-20 hero-section ">
      <div className="hero-content lg:px-64">
        <h1 className="text-2xl  md:text-4xl lg:text-4xl font-bold mb-3">
          AI{" "}
          <span className="magic">
            <span class="magic-star">
              <svg viewBox="0 0 512 512">
                <path d="M512 255.1c0 11.34-7.406 20.86-18.44 23.64l-171.3 42.78l-42.78 171.1C276.7 504.6 267.2 512 255.9 512s-20.84-7.406-23.62-18.44l-42.66-171.2L18.47 279.6C7.406 276.8 0 267.3 0 255.1c0-11.34 7.406-20.83 18.44-23.61l171.2-42.78l42.78-171.1C235.2 7.406 244.7 0 256 0s20.84 7.406 23.62 18.44l42.78 171.2l171.2 42.78C504.6 235.2 512 244.6 512 255.1z" />
              </svg>
            </span>
            <span class="magic-star">
              <svg viewBox="0 0 512 512">
                <path d="M512 255.1c0 11.34-7.406 20.86-18.44 23.64l-171.3 42.78l-42.78 171.1C276.7 504.6 267.2 512 255.9 512s-20.84-7.406-23.62-18.44l-42.66-171.2L18.47 279.6C7.406 276.8 0 267.3 0 255.1c0-11.34 7.406-20.83 18.44-23.61l171.2-42.78l42.78-171.1C235.2 7.406 244.7 0 256 0s20.84 7.406 23.62 18.44l42.78 171.2l171.2 42.78C504.6 235.2 512 244.6 512 255.1z" />
              </svg>
            </span>
            <span class="magic-star">
              <svg viewBox="0 0 512 512">
                <path d="M512 255.1c0 11.34-7.406 20.86-18.44 23.64l-171.3 42.78l-42.78 171.1C276.7 504.6 267.2 512 255.9 512s-20.84-7.406-23.62-18.44l-42.66-171.2L18.47 279.6C7.406 276.8 0 267.3 0 255.1c0-11.34 7.406-20.83 18.44-23.61l171.2-42.78l42.78-171.1C235.2 7.406 244.7 0 256 0s20.84 7.406 23.62 18.44l42.78 171.2l171.2 42.78C504.6 235.2 512 244.6 512 255.1z" />
              </svg>
            </span>
            <span className="magic-text">sentiment-based</span>
          </span>{" "}
          analysis across all your favourite tokens
        </h1>
        <p className="text-sm md:text-md font-light mb-8 px-4 sm:px-10 md:px-20 lg:px-40">
          NEW WEBSITE LIVE AT <a href="https://trysentiment.ai/" target="_blank" rel="noopener noreferrer">https://trysentiment.ai/</a>
        </p>
        <p className="text-md md:text-lg mb-8 font-light sm:px-10 md:px-20 lg:px-40 text-gray-300">
          CA: 0x8dbd1331b1de57835b24657ed21d0691e2e7362a
        </p>
      </div>
      <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-6">
        <Button
          onClick={() => {
            window.open("https://twitter.com/sentimentai_xyz", "_blank");
          }}
          message="Learn more"
        />
        <Button
          onClick={() => {
            window.open(
              "https://www.dextools.io/app/en/ether/pair-explorer/0xb42cf248b2e1a309183762ad4a050836e6303fac",
              "_blank"
            );
          }}
          message="$SENT"
        />
      </div>
    </section>
  );
};

export default HeroSection;
