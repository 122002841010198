import React from 'react';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import CardsSection from './components/CardsSection';
import FinalSection from './components/FinalSection';
import Footer from './components/Footer';

function App() {
  return (
    <div className="flex flex-col min-h-screen bg-black text-white overflow-x-hidden">
      <Navbar />
      <main className="flex flex-col flex-1  sm:px-5 ">
        <HeroSection />
        <CardsSection />
        <FinalSection />
      </main>
      <Footer />
    </div>
  );
}

export default App;
